import React, { useEffect, useState } from 'react'
import Nav from '../../components/nav/Nav'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import ListGrid from '../../components/listGrid/ListGrid'
import generalProducts from "../../resources/JSON/generalProducts.json"
import PETProducts from "../../resources/JSON/PETProducts.json";
import flexibleProducts from "../../resources/JSON/flexibleProducts.json";
import rubberProducts from "../../resources/JSON/rubberProducts.json";
import corrugatedPackaging from "../../resources/JSON/corrugatedPackaging.json";
import wovenSacksAndJute from "../../resources/JSON/woven-sacks-and-jute.json";
import plywoodAndLaminate from "../../resources/JSON/plywood-and-laminate-testing.json";
import { useParams } from 'react-router-dom'

const List = () => {
  const{id} = useParams();
  const [catName, setCatName] = useState("");
  const [category,setCategory] = useState([]);
  useEffect(() => {
    chooseCategory();
  });
  
  const chooseCategory = () =>{
    if(id === "general-testing"){
      setCatName("General Testing Instruments");
      setCategory(generalProducts);
    }else if(id === "pet-bottle-testing-instruments"){
      setCatName("PET Bottle Testing Instruments");
      setCategory(PETProducts);
    }else if(id === "rubber-testing"){
      setCatName("Rubber Testing Instruments");
      setCategory(rubberProducts);
    }else if(id === "flexible-paper-packaging"){
      setCatName("Flexible Packaging Testing Instruments");
      setCategory(flexibleProducts);
    }else if(id === "corrugated-packaging"){
      setCatName("Paper & Corrugated Packaging Testing Instruments");
      setCategory(corrugatedPackaging);
    }else if(id === "plywood-and-laminate-testing"){
      setCatName("Plywood & Laminate Testing Instruments");
      setCategory(plywoodAndLaminate);
    }else if(id === "woven-sacks-and-jute"){
      setCatName("Woven Sacks and Jute Testing Instruments");
      setCategory(wovenSacksAndJute);
    }else if(id === "pipe-testing"){
      setCatName("Pipe Testing Instruments");
    }
  }
  return (
    <div>
      <Nav/>
      <Header name={catName} />
      <ListGrid cat={category} />
      <Footer/>
    </div>
  )
}

export default List