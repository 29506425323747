import React from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'; 
//import CardMedia from '@mui/material/CardMedia';
// import { Link } from 'react-router-dom';
import catalog from "../../resources/JSON/category.json"
import {CardActionArea } from '@mui/material';

//import pic from "./catalogue-1.jpeg";

// import image from "./category-images/general.jpg"

const Catalog = () => {
  return (
    <div className="catalog" id='category' >
      <div className="bg" style={{background:'rgb(243,243,243)'}}>
        <Box className="max-width" sx={{maxWidth: '1300px',margin:'auto', padding: {xs:'70px 40px',md:'80px 80px'}}}>
          <div className="heading" style={{ textAlign:'center', marginBottom:'30px'}}>
              <Typography sx={{fontSize: '40px'}}>CATALOG</Typography>
              <Typography sx={{fontSize: '20px', margin: '-10px'}}>what we provide</Typography>
          </div>
          <Box sx={{ flexGrow: 1, padding: {xs:'0 0',md:'0 80px'}}}>
          <Grid container spacing={6}>
            {catalog && catalog.map((c)=>(
              <Grid item xs={12} sm = {6} md = {4}  key={c.cid}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardActionArea href={`/products/${c.cid}`}>
                    {/* <CardMedia
                      component="img"
                      height="220"
                      image={c.image}
                      alt="green iguana"
                    /> */}
                    <img src={c.image} className="card-img-top" alt={c.name} />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {c.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  
                </Card>
              </Grid>
            ))}
            </Grid>
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default Catalog