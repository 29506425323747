import React from 'react'
import Footer from '../../components/footer/Footer'
import Nav from '../../components/nav/Nav'

const Contact = () => {
  return (
    <div>
      <Nav/>
      {/* //<ContactInfo/> */}
      <Footer/>
    </div>
  )
}

export default Contact