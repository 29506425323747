import React from 'react'
import Nav from '../../components/nav/Nav'
import Header from '../../components/header/Header'
import Info from '../../components/info/Info'
import Footer from '../../components/footer/Footer'
import products from '../../resources/JSON/products.json'
import { useParams } from 'react-router-dom'

const Product= () => {
  const{pid} = useParams();

  return (
    <div>
      <Nav/>
      <Header name={products[pid].prodName}/>
      <Info product={products[pid]}/>
      <Footer/>
    </div>
  )
}

export default Product