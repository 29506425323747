import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home";
import List from "./pages/list/List";
import Product from "./pages/product/Product";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/products/:id" element={<List/>}/>
          <Route path="/products/:cid/:pid" element={<Product/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/contact" element={<Contact/>}/>
      </Routes>
    </BrowserRouter>
    );
  }
  
export default App;