import React from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import one from './1.png';
import two from './2.jpg';
import three from './3.jpg';
import four from './4.jpg';
import five from './5.jpg';
import six from './6.jpg';
import seven from './7.jpg';
import eight from './8.jpg';
import nine from './9.jpg';
import ten from './10.jpg';
import eleven from './11.jpg';
import twelve from './12.jpg';
import thirteen from './13.png';

const Trust = () => {
  return (
    <div className="trust">
      <Box className="max-width" sx={{maxWidth: '1300px',margin:'auto', padding: {xs:'70px 40px',md:'80px 80px'}}}>
        <div className="heading" style={{ textAlign:'center', marginBottom:'30px'}}>
            <Typography sx={{fontSize: '40px'}}>TRUSTED BY OUR ESTEEMED CLIENTS</Typography>
            <Typography sx={{fontSize: '20px', margin: '-10px'}}>who we have worked with</Typography>
        </div>
        <Box sx={{ flexGrow: 1, padding: {xs:'0 0',md:'0 80px'}}}>
          <Grid container spacing={2}>
            {[one,two,three,four,five,six,seven,eight,nine,ten,eleven,twelve,thirteen].map((number)=>(
            <Grid item xs={6} md = {2} key={number}>
              <Box
                sx={{
                  display: 'flex',
                  '& > :not(style)': {
                    m: 1,
                    width: 128,
                    height: 128,
                  },
                }}>
                <Paper variant="outlined" square sx={{textAlign:'center'}}>
                  <img src={`${number}`} alt="" height="120px" width="120px"/>
                </Paper> 
              </Box>
            </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  )
}

export default Trust