import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
//import catalog from "../../resources/JSON/category.json"
//import products from "../../resources/JSON/generalProducts.json"
import { useParams } from 'react-router-dom';

const ListGrid = (props) => { 
  const{id} = useParams(); 
  return (
    <div className="listGrid">
      <div className="bg" style={{background:'rgb(243,243,243)'}}>
        <Box className="max-width" sx={{maxWidth: '1300px',margin:'auto', padding: {xs:'70px 40px',md:'40px 40px'}}}>
          <Box sx={{ flexGrow: 1, padding: {xs:'0 0',md:'0 0px'}}}>
            <Grid container spacing={4}>
              {props.cat.map((p)=>(
                <Grid item xs={12} sm = {6} md = {4} key={p.pid}>
                  <Card sx={{ maxWidth: 360 }}>
                    <CardActionArea href={`/products/${id}/${p.pid}`}>
                      <CardMedia
                        style={{margin:"10px", objectFit:"fill", width: "-webkit-fill-available"}}
                        component="img"
                        height="300px"
                        image={p.pImage}
                        alt="green iguana"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" textAlign={'center'} component="div">
                          {p.pName}
                        </Typography>
                        <Typography variant="body2" textAlign={'center'} color="text.secondary">
                          {p.pDesc}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    {/* <CardActions>
                      <Link className='btn btn-small' to={`/products/${id}/${p.id}`} >
                        Click for more details
                      </Link>
                    </CardActions> */}
                  </Card>
                </Grid>
              ))}
              
            </Grid>
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default ListGrid