import React from 'react'
import Stack from '@mui/material/Stack';

const Footer = () => {
  return (
    <div className="footer">
      <div className="max-width" style={{background:'#1a1d3a', color:'#fff', fontSize:'14px', padding:'80px 0', alignContent:'center'}}>
        <div className="content" style={{ padding:'0 80px', maxWidth:'1300px'}}>
          <Stack direction={{xs:'column', sm:'row'}} spacing={{xs: 1, sm: 2, md: 4}}>
            <div className="f-about" style={{width:'50%'}}>
              <div className="logo"></div>
              <div className="address-heading" style={{color: '#b49a59',fontWeight: '600', fontSize:'15px'}}> Location:</div>
              <div className="address0">T 8C/1E JOGODYAN LANE, KANKURGACHI</div>
              <div className="address1">KOLKATA - 700054, INDIA</div>
              <div className="contact-heading" style={{color: '#b49a59',fontWeight: '600', fontSize:'15px'}}> Contact:</div>
              <div className="contact-p">+91 9051049324</div>
              <div className="Email-heading" style={{color: '#b49a59',fontWeight: '600', fontSize:'15px'}}> Email:</div>
              <div className="contact-e">akashententerprises2003@gmail.com</div>
              <div className="socials"></div>
            </div>
            
            <div className="popular-catelog" style={{ width: {xs:'100%',md:'30%'}}}>
              <div className="heading" style={{color: '#b49a59',fontWeight: '600', fontSize:'25px', marginBottom:'20px'}}>
                Popular Products
              </div>
              <div className="list">
                <li><a href="./">PET Bottle</a></li>
                <li><a href="./">Rubber Testing</a></li>
                <li><a href="./">Flexible Packaging</a></li>
                <li><a href="./">Plywood & Laminate</a></li>
              </div>
            </div>
            
            <div className="nav-category" style={{ width: {xs:'100%',md:'20%'}}}>
              <div className="heading" style={{color: '#b49a59',fontWeight: '600', fontSize:'25px', marginBottom:'20px'}}>
                About us
              </div>
              <div className="list">
                <li><a href="./">Home</a></li>
                <li><a href="./about">About us</a></li>
                <li><a href="./contact">Contact us</a></li>
              </div>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  )
}

export default Footer