import React from 'react'
import Nav from '../../components/nav/Nav'
import Banner from '../../components/banner/Banner'
import About from '../../components/about/About'
import Trust from '../../components/trust/Trust'
import Catalog from '../../components/catalog/Catalog'
import Contact from '../../components/contact/Contact'
import Footer from '../../components/footer/Footer'

const Home = () => {
  return (
    <div>
      <Nav/>
      <Banner/>
      <About/>
      <Trust/>
      <Catalog/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default Home