import React from 'react'
import "./nav.css"
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import logo from './logo-brand-1.png';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { createTheme , ThemeProvider} from '@mui/material/styles';
import { Link } from 'react-router-dom';
import catalog from "../../resources/JSON/category.json"
import {useNavigate} from 'react-router-dom';

const theme = createTheme({
    palette: {
      primary: {
        main : '#282e68',
      },
      secondary: {
        main : '#808080',
      },
      tertiary: {
        main : '#e7e9f0'
      }
    },
});

const Nav = () => {
  const [menu, setMenu] = React.useState(null);
  const navigate = useNavigate();
  const handleOpenMenu = (event) =>{
    setMenu(event.currentTarget);
  }
  const handleCloseMenu = (id) => {
    setMenu(null);
    // const l = "/products/"+id;
    navigate('/products/'+id);
  }
  const reloadPage = () => {
    window.location.reload()
  }
  return (
    <div className="nav">
      <div className="row1" style={{width:"100%"}}>
        <Stack justifyContent="space-around" alignItems="center" direction="row">
          <Link className="logo" to="/">
            <img src={logo} alt="logo" height="70px"/>
          </Link>
          <div className="main_nav">
            <Box sx={{display: {xs: 'none', md: 'flex'}}}>
              <Stack spacing={1} direction="row">
                <ThemeProvider theme={theme}>
                  <Button sx={{fontWeight: '700', fontSize: '17px'}} href='/'>Home</Button>
                  <Button sx={{fontWeight: '700', fontSize: '17px'}} href='/about'>About Us</Button>
                  <Button sx={{fontWeight: '700', fontSize: '17px'}} href='/contact'>Contact Us</Button>
                </ThemeProvider>
              </Stack>
            </Box>
          </div>
          <div className="side_info">
            <Box sx={{display: {xs: 'none', md: 'block'}}}>
              <ThemeProvider theme={theme}>
                <div className="phn_number" style={{textAlign: 'right'}}>
                  <Button sx={{fontSize: '14px'}} color='secondary' startIcon={<CallIcon sx={{fontSize: '18px'}} color='secondary'/>}>  Contact: +91 9051049324</Button>
                </div>
                <div className="email">
                  <Button sx={{fontSize: '14px'}} color='secondary'startIcon={<EmailIcon sx={{fontSize: '18px'}} color='secondary'/>} href='mailto:akashenterprises2003@gmail.com'>  Email: akashenterprises2003@gmail.com</Button>
                </div>
              </ThemeProvider>
            </Box>
          </div>
          <Box sx={{display: {xs: 'flex', md: 'none'}}}>
            <div className="menu">
              <IconButton size="large" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenMenu}><MenuIcon/></IconButton>
              <Menu 
               id="menu-appbar"
               anchorEl={menu}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}
               keepMounted
               transformOrigin={{
                 vertical: 'top',
                 horizontal: 'left',
               }}
               open={Boolean(menu)}
               onClose={handleCloseMenu}
               sx={{
                 display: { xs: 'block', md: 'none' },
               }}>
                <ThemeProvider theme={theme}>
                {catalog.map((c)=>(
                  <MenuItem onClick={() => handleCloseMenu(c.cid)}>
                    <Button>{c.name}</Button>
                  </MenuItem>
                ))}
                  {/* <MenuItem onClick={handleCloseMenu}>
                    <Button>All Instruments</Button>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <Button>General</Button>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <Button>PET Bottle</Button>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <Button>Packaging</Button>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <Button>Plywood & Laminate</Button>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <Button>Woven Sacks & Jute</Button>
                  </MenuItem> */}
                </ThemeProvider>
              </Menu>
            </div>
          </Box>
          </Stack>
      </div>
      <Divider/>
      <div className="row2" style={{padding: '6px 0 6px 0'}}>
        <Box sx={{display: {xs: 'none', md: 'flex'}}}>
          <ThemeProvider theme={theme}> 
            <Stack spacing={2} direction="row">
              <Button variant='contained' sx={{cursor:'default'}} color='tertiary'disableElevation>Categories</Button>
              {/* <Link  to={`/#category`}>All Instruments</Link> */}
              {catalog.map((c)=>(
                <Button onClick={reloadPage}>
                <Link style={{color:"#595c8a", textDecoration:"none"}} to={`/products/${c.cid}/`}>{c.shortName}</Link></Button>
              ))}
              
            </Stack>
          </ThemeProvider>
        </Box>
      </div>
      
    </div>
  )
}

export default Nav