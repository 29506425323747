import React from 'react'
import Box from '@mui/material/Box';
import image from './header-banner.jpg'
// import { useParams } from 'react-router-dom';

const Header = (props) => {
  // const{cid} = useParams(); 
  return (
    <div className="header">
      <div className="bg" style={{backgroundImage:`url(${image})`, backgroundSize:'cover'}}>
        <Box className="max-width" sx={{maxWidth: '1300px', margin:'auto', padding: {xs:'25px 40px',md:'35px 80px'}, textAlign:'center', color:'white'}}>
          <div className="title" style={{fontSize:"32px"}}>{props.name}</div>
          {/* <div className="header-address" >
            <ul style={{display:'flex',justifyContent:'center', listStyle:'none'}}>
              <li>{cid}</li>
              <span style={{color:'rgb(177,177,177)'}}>/</span>
              <li>{props.name}</li>
              <span style={{color:'rgb(177,177,177)'}}>/</span>
              <li>3</li>
            </ul>
          </div> */}
        </Box>
      </div>
    </div>
  )
}

export default Header