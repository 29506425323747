import React from 'react'
import cover from './cover.png';
import coverxs from './coverShort.png';
import Box from '@mui/material/Box';

const Banner = () => {
  return (
    <div className="banner">
       <Box sx={{backgroundImage: {xs: `url(${coverxs})`, md:`url(${cover})`}, backgroundSize: 'cover'}}>
        <div className="maxWidth" style={{display:'flex', height: '90vh'}}>
          <Box sx={{margin: {xs: '100px 0 0 0', md: '180px 0 auto 130px'}, padding: {xs: '0 10px', md: '0 80px'}}}>
            <div className="text-content">
                <div className="brand-name" style={{display:'flex',fontSize: '60px', fontWeight:'800'}}>
                    <p style={{marginBlockEnd:'0'}}>Akash Enterprises</p>
                </div>
                <div className="brand-desc" style={{fontSize: '20px'}}>
                  <p>Manufacturer and Supplier of Quality Testing Instruments</p>
                  </div>
            </div>
          </Box>
        </div>
      </Box>
    </div>
  )
}

export default Banner