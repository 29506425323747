import React from 'react'
import Box from '@mui/material/Box';
//import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
//import { Link } from 'react-router-dom';

const Info = (props) => {

  return (
    /*
      Picture
      <br />
      Overview
      <br />
      Quote PDF
      <br />
      Specification
      <br />
      Details   
      <br />
      ??OtherProducts?? 
    */
    <div className="info">
      <Box className="max-width" sx={{maxWidth: '1300px',margin:'auto', padding: {xs:'70px 40px',md:'80px 80px'}}}>
        <Box sx={{display:{xs:'block', md:'flex'}}}>
          <div className="image" style={{ margin:"20px"}} >
            <Box
              sx={{
                display: 'flex',
                '& > :not(style)': {
                  m: 1,
                  width: 300,
                  height: 350,
                }, width:{xs:'100%', md:'50%'}}}>
              
                <img src={ props.product.prodImage } alt="..loading" height="350px" width="300px" />
             
            </Box>
          </div>
          <div className="content" >
            <Box>
              <div className="overview">
                <Typography variant="h4">Overview</Typography>
                <br />
                <ul>
                {props.product.prodOverview.split('</li><li>').map((point)=>(
                  <li key={point.index}>{point}</li>
                  ))}
                </ul>
              </div>
            </Box>
            <Box>
              <Typography>Model: {props.product.prodModel}</Typography>
              <br />
            </Box>
            <Box>
              <div className="buttons">
                <Button sx={{margin:'0 10px 0 0'}} variant="contained">Contact to Get a Quote</Button>
                <a className='btn btn-primary' target="_blank" rel="noreferrer" href={props.product.prodPdf} variant="contained">Download PDF</a>
              </div>
              <br></br>
            </Box>
            <Box>
              <div className="specification">
                <h2>Specification: </h2><br />
                <table className="table table-striped table-bordered">
                  {/* <tbody className='table-light' style={{backgroundColor:"white"}}> */}
                  <tr>
                    {props.product.prodSpecs.split('</td></tr>').map((spec)=>(
                      <tr key={spec.index}>
                        <td>
                          <b>{spec.split('</b></td><td>')[0] }</b>
                        </td>
                        <td>
                          {spec.split('</b></td><td>')[1]}
                        </td>
                      </tr>
                    ))}
                  </tr>
                  {/* </tbody> */}
                </table>
              </div>
            </Box>
          </div>
        </Box>
      </Box>
    </div>
  )
}

export default Info