import React from 'react'
import Nav from '../../components/nav/Nav'
import Aboutt from '../../components/about/About'
import Footer from '../../components/footer/Footer'

const About = () => {
  return (
    <div>
      <Nav/>
      <Aboutt/>
      <Footer/>
    </div>
  )
}

export default About