import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const About = () => {
  return (
    <div className="about">
        <Box className="max-width" sx={{maxWidth: '1300px',margin:'auto', padding: {xs:'70px 40px',md:'100px 80px'}}}>
            <div className="heading" style={{ textAlign:'center', marginBottom:'30px'}}>
                <Typography sx={{fontSize: '40px'}}>About Us</Typography>
                <Typography sx={{fontSize: '20px', margin: '-10px'}}>who we are</Typography>
            </div>
            <Box sx={{display:{xs:'block', md:'flex'}, justifyContent:'center'}}>
                <Box sx={{width:{xs:'100%', md:'50%'}}}>
                    <div className="left">
                            <h1>The Best Manufacturing in Eastern India</h1>
                            <div class="hr-about" style={{width:"90px", backgroundColor:"#b49a59",height:"5px", borderRadius:"2px", marginTop:"20px"}}>&nbsp;</div>
                    </div>
                </Box>
                <Box sx={{width:{xs:'100%', md:'50%'}}}>
                    <div className="right">
                            <Typography sx={{fontSize: '15px', marginBottom: '10px', textAlign: 'justify'}}>Akash Enterprises, Manufacturer and Supplier of Quality Testing Instruments, produces a wide range of testing equipment for use in the Flexible Packaging, PET, and other related industries. Our customers' continued support and orders are proof of our high quality standards. We believe in long-term quality, not simply 'short-term excellence'. We have always placed a higher priority on providing consumers with "Value for Money", which has resulted in significant goodwill for the company over the years.</Typography>
                            <p>The company was founded in 2007 with the manufacturing facility located in Kolkata, India.</p>
                            <p>We are an ISO ASTM TAPPAI BIS:9001:2015 accredited firm that designs and manufactures testing equipment as well as a variety of other products that meet CE and European quality standards. The company is committed to the notion that human resources are a valuable asset and that all of the company's actions are customer-centric, and it is even more committed to the belief that leadership should be maintained through technology.</p>
                    </div>
                </Box>
            </Box>
        </Box>
    </div>
  )
}

export default About