import React from 'react';

const ContactSection = () => {
    return (
        // <section className="contact" id="contact">
        //     <div className="max-width">
        //         <h2 className="title">CONTACT US</h2>
        //         <div className="contact-content">
        //             <div className="column left">
        //                 <div className="text">Get in Touch</div>
        //                 <p>Please fill out the form and we will contact you, or use the contact details below to get in touch with us.</p>
        //                 <div className="icons">
        //                     <div className="row">
        //                         <i className="fas fa-user"></i>
        //                         <div className="info">
        //                             <div className="head">Name</div>
        //                             <div className="sub-title">Akash Enterprises</div>
        //                         </div>
        //                     </div>
        //                     <div className="row">
        //                         <i className="fas fa-map-marker-alt"></i>
        //                         <div className="info">
        //                             <div className="head">Address</div>
        //                             <div className="sub-title">Kadapara, Phool Bagan, Kankurgachi, Kolkata, West Bengal 700054, India</div>
        //                         </div>
        //                     </div>
        //                     <div className="row">
        //                         <i className="fas fa-envelope"></i>
        //                         <div className="info">
        //                             <div className="head">Email</div>
        //                             <div className="sub-title"><a style={{ color: '#111' }} href="mailto:archanasharma2797@gmail.com">akashenterprises2005@gmail.com</a></div>
        //                         </div>
        //                     </div>
        //                     <div className="row">
        //                         <i className="fas fa-phone-alt"></i>
        //                         <div className="info">
        //                             <div className="head">Mobile Number</div>
        //                             <div className="sub-title">+91 9051049324</div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="column right">
        //                 <div className="text">Message Us</div>
        //                 <form id="contact-form" method="POST" action="contact-form.php">
        //                     <div className="fields">
        //                         <div className="field name">
        //                             <input name="name" type="text" placeholder="Name" required />
        //                         </div>
        //                         <div className="field Email">
        //                             <input name="email" type="email" placeholder="Email" required />
        //                         </div>
        //                     </div>
        //                     <div className="field">
        //                         <input name="subject" type="text" placeholder="Subject" required />
        //                     </div>
        //                     <div className="field textarea">
        //                         <textarea name="message" cols="30" rows="10" placeholder="Describe Project..." required></textarea>
        //                     </div>
        //                     <div className="button">
        //                         <button name="submit" type="submit">Submit</button>
        //                     </div>
        //                 </form>
        //             </div>
        //         </div>
        //     </div>
        // </section>
        <div style={{textAlign:"center",fontWeight:"900"}}>
            For more details <span style={{color:"green"}}>Whatsapp</span> on +91 9051049324
        </div>
    );
}

export default ContactSection;
